//@flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderRadius: '3px',
    fontFamily: 'Libre Franklin',
    fontSize: '14px',
    padding: '6px 16px',
  },
  message: {
    flex: 1,
  },
  action: {
    margin: 0,
    padding: 0,
  },
});

const GdSnackbarContent = (props) => {
  const { classes } = props;

  return <SnackbarContent {...props} className={classes.root} />;
};

export default withStyles(styles)(GdSnackbarContent);
