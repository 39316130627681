import React from 'react';
import { Text } from '../../lib';

const NotFound = () => {
  return (
    <div>
      <Text size={24} weight={600}>
        Not Found
      </Text>
    </div>
  );
};

export default NotFound;
