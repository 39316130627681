// @flow

import type { Action, GbdStateType } from '../types';
import { defaultObjectState } from '../types';
import { REQUEST_SIGNIN_SUCCESS, REQUEST_SIGNIN_FAIL } from '../pages/signin/actions';

const user = (state: GbdStateType = defaultObjectState, action: Action<*>) => {
  switch (action.type) {
    case REQUEST_SIGNIN_SUCCESS:
      return { ...state, data: action.payload.user, authenticated: true, loading: false };
    case REQUEST_SIGNIN_FAIL:
      return { ...state, data: {}, authenticated: false, loading: false };
    default:
      return { ...state };
  }
};

export { user };
