//@flow
import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';

const ButtonWrap = styled(MuiButton)`
  background: ${(props) => props.background};
  color: ${(props) => props.color} !important;
  border: ${(props) => props.border} !important;
  font-size: ${(props) => props.fontSize} !important;
  border-style: inset;
  width: 100% !important;
`;

const Button = (props: any) => {
  const { theme, type } = props;

  return (
    <ButtonWrap
      {...props}
      {...(theme && theme.overrides ? theme.overrides.MuiButtonTypes[type] : {})}
    />
  );
};

export default withTheme()(Button);
