import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  margin: 0;
  padding: 0 8px;
  max-width: 420px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const PageContentInner = styled(Grid)`
  min-width: 100%;
  padding: 0 30px;
`;

export const CenteredGridItem = styled(Grid)`
  text-align: center;
`;

export const Footer = styled(Grid)`
  margin: auto 0 5px;
`;

export const HelperGridMargin = styled.div`
  margin-bottom: 15px;
`;

export const InputWrap = styled(Grid)`
  position: relative;
  height: 75px;
  width: 100%;

  input {
    padding-left: ${(props) => (props.icon ? '26' : '3')}px;
  }

  label {
    padding-left: ${(props) => (props.icon ? '35' : '3')}px;
  }
`;
