// @flow
import type { Action, User } from '../../types';

export const REQUEST_SIGNIN = 'REQUEST_SIGNIN';
export const REQUEST_SIGNIN_SUCCESS = 'REQUEST_SIGNIN_SUCCESS';
export const REQUEST_SIGNIN_FAIL = 'REQUEST_SIGNIN_FAIL';
export const REQUEST_SIGNIN_ERROR_CLEAR = 'REQUEST_SIGNIN_ERROR_CLEAR';

export const requestSignin = (): Action<*> => {
  return {
    type: REQUEST_SIGNIN,
    payload: undefined,
  };
};

export const requestSigninSuccess = (user: User): Action<*> => {
  return {
    type: REQUEST_SIGNIN_SUCCESS,
    payload: {
      user,
    },
  };
};

export const requestSigninFail = (message: string): Action<*> => {
  return {
    type: REQUEST_SIGNIN_FAIL,
    payload: {
      message,
    },
  };
};

export const requestSigninErrorClear = (): Action<*> => {
  return {
    type: REQUEST_SIGNIN_ERROR_CLEAR,
    payload: undefined,
  };
};
