//@flow
import React from 'react';
import styled from 'styled-components';
import Input from './Input';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Grid from '@material-ui/core/Grid';

const GdDateWrap = styled(Grid)`
  position: relative;
  height: 72px;
  width: 100%;
`;

const DateIcon = styled(CalendarToday)`
  opacity: 0.7;
  top: 20px;
  position: absolute;
  transform: scale(0.9);
`;

const DateInput = (props: any) => {
  const { min, max } = props;
  return (
    <GdDateWrap>
      <Input
        {...props}
        icon={true}
        type="date"
        inputProps={{
          min,
          max,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <DateIcon />
    </GdDateWrap>
  );
};

export default DateInput;
