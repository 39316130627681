//@flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

const styles = (theme) => ({
  root: {
    height: '65px',
    margin: theme.spacing.unit,
  },
  anchorOriginBottomLeft: {
    right: '0',
    left: '0',
    justifyContent: 'center',
  },
});

const GdSnackbar = (props) => {
  const { classes } = props;

  return <Snackbar {...props} className={classes.root} />;
};

export default withStyles(styles)(GdSnackbar);
