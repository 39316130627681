//@flow
import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Text } from './Text';
import IconButton from './IconButton';
import Edit from '@material-ui/icons/Edit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const AvatarWrap = styled.div`
  position: relative;

  img {
    width: 100%;
  }

  button {
    position: absolute;
    bottom: 0;
    right: 13px;
    z-index: 10;
  }
`;

const ContentWrap = styled(Grid)`
  overflow: hidden;
  position: relative;
  background-color: ${props => props.theme.palette.primary.pink};
  border: 2px solid ${props => props.theme.palette.primary.contrastText};
  border-radius: 50%;
  box-shadow: 0 0 8px 0 ${props => props.theme.palette.shadow.primary};

  &&& {
    width: ${props =>
      props.size === 'large'
        ? '120px'
        : props.size === 'medium'
        ? '80px'
        : '30px'};
    height: ${props =>
      props.size === 'large'
        ? '120px'
        : props.size === 'medium'
        ? '80px'
        : '30px'};
  }

  span {
    color: ${props => props.theme.palette.primary.contrastText};
  }
`;

const IconWrap = styled(IconButton)`
  &&& {
    overflow: hidden;
    padding: 0;
    width: 26px;
    height: 26px;
    background-color: ${props => props.theme.palette.primary.pink};
    border: 2px solid ${props => props.theme.palette.primary.contrastText};
    color: ${props => props.theme.palette.primary.contrastText};
  }

  svg {
    font-size: 16px;
  }
`;

const user = {
  firstName: '',
  lastName: '',
  image: 'https://randomuser.me/api/portraits/women/17.jpg',
};

const Avatar = (props: any) => {
  const {
    user: {
      firstName = user.firstName,
      lastName = user.lastName,
      image = user.image,
    },
    onClick,
    onEditClick,
    editable = false,
  } = props;
  const userInitials = `${firstName.length > 0 ? firstName[0] : ''}${
    lastName.length > 0 ? lastName[0] : ''
  }`;

  return (
    <AvatarWrap
      {...props}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {image && (
        <ContentWrap {...props}>
          <img src={image} alt={userInitials} />
        </ContentWrap>
      )}
      {!image && (
        <ContentWrap {...props} container alignItems="center" justify="center">
          <Grid item>
            <Text size={30} weight={300}>
              {userInitials}
            </Text>
          </Grid>
        </ContentWrap>
      )}
      {editable && (
        <IconWrap
          {...props}
          onClick={e => {
            e.stopPropagation();
            if (onEditClick) onEditClick();
          }}
        >
          <Edit />
        </IconWrap>
      )}
    </AvatarWrap>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

const mapStateToProps = () => state => ({
  user: state.user.data || user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme()(Avatar));
