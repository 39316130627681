//@flow
import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
const numeral = require('numeral');

const BadgeWrap = styled.a`
  flex: 1;
  text-decoration: none;

  svg {
    margin-right: 9px;
    font-size: 20px;
  }
`

const GdBadge = (props: any) => {
  const { onClick } = props;

  return (
    <BadgeWrap href={props.url} onClick={() => {if(onClick) onClick()}}>
      <IconButton aria-label={props.label}>
        <Badge badgeContent={props.count > 999 ? numeral(props.count).format('0.0a') : props.count}>
          {props.children}
        </Badge>
      </IconButton>
    </BadgeWrap>
  );
};

export default GdBadge;
