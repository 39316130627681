//@flow
import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const GdIconButtonWrap = styled(IconButton)`
  &&& {
    padding: 8px;
    color: ${({ color = 'rgba(0, 0, 0, 0.54)' }) => color};
  }
`;

const GdIconButton = (props: any) => {
  return <GdIconButtonWrap {...props}>{props.children}</GdIconButtonWrap>;
};

export default GdIconButton;
