//@flow
import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const PinWrap = styled(Grid)`
  margin: 30px 0 50px;
  width: 55px;
  height: 55px;
  border-radius: 4px;
  border: 2px solid
    ${(props) =>
      props.disabled ? props.theme.palette.border.disabled : props.theme.palette.border.secondary};

  input {
    width: 100%;
    background: transparent;
    height: 100%;
    border: none;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    text-align: center;

    &:focus,
    &:active {
      outline: none;
    }
  }
`;

const PinInput = (props: any) => {
  const { theme, inputRef, disabled, onChange, ...rest } = props;

  return (
    <PinWrap {...rest} theme={theme} disabled={disabled}>
      <input disabled={disabled} type="number" max="9" onChange={onChange} ref={inputRef} />
    </PinWrap>
  );
};

export default withTheme()(PinInput);
