import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
import sagas from '../sagas';
import { routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';

export const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middlewares = applyMiddleware(sagaMiddleware, routeMiddleware);

export default function configureStore() {
  let store = createStore(reducers(history), composeEnhancers(middlewares));

  sagas.map((saga) => sagaMiddleware.run(saga));

  return store;
}
