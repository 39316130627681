//@flow
import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoaderWrap = styled(Grid)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
`;

const Loader = (props: any) => {
  return (
    <React.Fragment>
      {props.isVisible && (
        <LoaderWrap container>
          <CircularProgress color="primary" />
        </LoaderWrap>
      )}
    </React.Fragment>
  );
};

export default Loader;
