//@flow
import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const ItemWrap = styled.div`
  overflow: hidden;
  max-width: 224px;
  background-color: ${(props) => props.theme.palette.primary.alabaster};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.palette.shadow.multiple};
  text-align: left;
`;

export const ItemImage = styled.div`
  width: 100%;
  height: 150px;
  background-image: ${(props) => (props.image)};
  background-size: cover;
`;

export const ItemContent = styled(Grid)`
  padding: 12px 16px;
  min-height: 150px;

  span {
    overflow: hidden;
    display: inline-flex;
    max-height: 67px;
    line-height: 1.35;

    &:last-child {
      margin-top: auto;
    }
  }
`;

const CarouselItem = (props: any) => {
  return (
    <ItemWrap {...props}>
      <ItemImage image={props.image} />
      <ItemContent container>{props.children}</ItemContent>
    </ItemWrap>
  );
};

export default withTheme()(CarouselItem);
