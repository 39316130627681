//@flow
import React from 'react';
import styled from 'styled-components';

const LinkWrap = styled.a`
  font-size: ${({ size = 12 }) => size}px;
  color: ${({ color = '#e72413' }) => color};
  font-weight: ${({ weight = 'normal' }) => weight};
  font-style: normal;
  font-family: Poppins;
  line-height: ${({ lineHeight = '1.5' }) => lineHeight};
  text-decoration: ${({ textDecoration = 'none' }) => textDecoration};
`;

const Link = (props: any) => {
  return (
    <LinkWrap
      href={props.url}
      size={props.size}
      color={props.color}
      weight={props.weight}
      lineHeight={props.lineHeight}
      textDecoration={props.textDecoration}>
        {props.children}
    </LinkWrap>
  );
};

export default Link;
