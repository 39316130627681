import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { configureStore } from './config';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './config/store';
import Routes from './Routes';
import './styles/reset.css';
import 'typeface-poppins';
import 'typeface-libre-franklin';

const store = configureStore();
export const AppProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

if (process.env.NODE_ENV !== 'production') {
  const { whyDidYouUpdate } = require('why-did-you-update');
  whyDidYouUpdate(React, {
    include: [],
    exclude: [/^ScrollTransition/, /^StyledComponent/],
  });
}

const startApp = () => {
  ReactDOM.render(
    <AppProvider>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </AppProvider>,
    document.getElementById('root'),
  );
};

startApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
