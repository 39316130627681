//@flow
import React from 'react';
import styled from 'styled-components';
import Input from './Input';
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait';
import { InputWrap } from './styles';

const PhoneIcon = styled(StayCurrentPortrait)`
  opacity: 0.7;
  top: 20px;
  position: absolute;
  transform: scale(0.9);
`;

const PhoneInput = (props: any) => {
  return (
    <InputWrap>
      <Input {...props} icon="true" hint="Use your country code eg +44" />
      <PhoneIcon />
    </InputWrap>
  );
};

export default PhoneInput;
