//@flow
import React, { Component } from 'react';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withTheme } from '@material-ui/core/styles';

const AppbarWrap = styled(AppBar)`
  &&& {
    box-shadow: ${props =>
      props.transparent ? 'none' : props.theme.palette.shadow.secondary};
  }
`;

const ToolbarWrap = styled(Toolbar)`
  &&& {
    padding-left: 6px;
    padding-right: 6px;
    max-height: ${props => (props.transparent ? '40px' : '56px')};
    min-height:${props => (props.transparent ? '40px' : '56px')};
    background-color: ${props =>
      props.transparent
        ? 'transparent'
        : props.theme.palette.primary.contrastText};
`;

class TopNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { position: 'static' };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const positionChange = this.state.position !== nextState.position;
    const propsChange = this.props !== nextProps;
    return positionChange || propsChange;
  }

  handleScroll() {
    if (window.scrollY > 56) {
      this.setState({ position: 'fixed' });
    } else {
      this.setState({ position: 'static' });
    }
  }
  render() {
    const props = { ...this.props, ...this.state };
    return (
      <AppbarWrap {...props} color="default">
        <ToolbarWrap {...props}>{props.children}</ToolbarWrap>
      </AppbarWrap>
    );
  }
}

export default withTheme()(TopNavBar);
