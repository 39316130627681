// @flow

export type GbdStateType = {
  data: any,
  loading: boolean,
  error?: string,
};

export type GbdRequestType = {
  id?: string,
  payload: any,
};

export type GbdResponseType = {
  payload: any,
};

export type Action<P> = {
  type: string,
  payload: P,
};

export type User = {
  firstName: string,
  lastName: string,
  dob?: string,
  phone: string,
  postcode: string,
};

export const defaultArrayState: GbdStateType = {
  data: [],
  loading: false,
  error: undefined,
};

export const defaultObjectState: GbdStateType = {
  data: {},
  loading: false,
  error: undefined,
};
