//@flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Input from './Input';
import LockOutlined from '@material-ui/icons/LockOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import { InputWrap } from './styles';

const LockIcon = styled(LockOutlined)`
  opacity: 0.7;
  top: 20px;
  position: absolute;
  transform: scale(0.9);
`;

const VisbilityButton = styled(IconButton)`
  width: 20px;
  opacity: 0.7;
  top: 10px;
  position: absolute !important;
  transform: scale(0.9) !important;
  right: 10px;
`;

class PasswordInput extends Component<*, *> {
  state = { type: 'password' };
  render() {
    return (
      <InputWrap>
        <Input
          {...this.props}
          icon="true"
          hint="Needs to be between 8 and 14 symbols"
          type={this.state.type}
        />
        <LockIcon />
        <VisbilityButton
          onClick={() =>
            this.setState({ type: this.state.type === 'password' ? 'text' : 'password' })
          }
        >
          {this.state.type === 'password' && <VisibilityOutlined />}
          {this.state.type === 'text' && <VisibilityOffOutlined />}
        </VisbilityButton>
      </InputWrap>
    );
  }
}

export default PasswordInput;
