// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { user } from './user';
import { reducer as formReducer } from 'redux-form';

export default (history: any) =>
  combineReducers({
    user,
    router: connectRouter(history),
    form: formReducer,
  });
