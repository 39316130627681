//@flow
import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import Carousel from 'nuka-carousel';
import CarouselItem from './CarouselItem';
import { Text } from './Text';

const CarouselWrap = styled(Carousel)`
  &.slider {
    margin: 0 -10px 40px;

    .slider-control-centerleft,
    .slider-control-centerright {
      display: none;
    }
    /* fixes nuke-carousel https://github.com/FormidableLabs/nuka-carousel/issues/417 */
    .slider-list {
      height: auto !important;
    }

    .slider-slide {
      position: sticky !important;
    }
    /* fixes nuke-carousel https://github.com/FormidableLabs/nuka-carousel/issues/417 */
    .slider-control-bottomcenter {
      top: 326px;

      button {
        padding: 2px !important;
        color: ${props => props.theme.palette.primary.grey} !important;
        pointer-events: none;
      }
    }
  }
`;

const CarouselItemWrap = styled.a`
  display: inline-flex;
  padding: 10px;
  text-decoration: none;
`;

const GdCarousel = (props: any) => {
  return (
    <CarouselWrap
      heightMode={'max'}
      {...props}
      slidesToShow={1.5}
      cellAlign="left"
    >
      {props.items.map((item, i) => (
        <CarouselItemWrap
          key={i}
          href={item.url}
          onClick={() => {
            if (item.onClick) item.onClick();
          }}
        >
          <CarouselItem key={i} image={item.image}>
            <Text size={16} weight={500}>
              {item.title}
            </Text>
            <Text size={14}>{item.content}</Text>
            <Text size={12}>{item.date}</Text>
          </CarouselItem>
        </CarouselItemWrap>
      ))}
    </CarouselWrap>
  );
};

export default withTheme()(GdCarousel);
