// @flow
import { put, takeLatest, select } from 'redux-saga/effects';
import { REQUEST_SIGNIN_SUCCESS } from '../pages/signin/actions';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getResonatorUser, getAthlonUser } from '../services/user';
import { requestSigninSuccess, requestSigninFail } from '../pages/signin/actions';

function* trackRouter(action) {
  const {
    router: { location },
  } = yield select();

  const matchRsntr = matchPath(location.pathname, {
    path: '/rr/:code',
  });

  if (matchRsntr) {
    const users = yield getResonatorUser();
    const user = users.find((u) => u.code.toString() === matchRsntr.params.code);

    if (user) {
      yield put(requestSigninSuccess(user));
      return;
    }
  }

  const matchAthlon = matchPath(location.pathname, {
    path: '/a/:code',
  });

  if (matchAthlon) {
    const users = yield getAthlonUser();
    const user = users.find((u) => u.code.toString() === matchAthlon.params.code);

    yield put(requestSigninSuccess(user));

    return;
  }

  yield put(requestSigninFail());
}

function* setUserRequest(action) {
  try {
    window.localStorage.setItem('token', action.payload.user.token);

    //yield put(push('/dashboard'));
  } catch (e) {
    console.log(e);
  }
}

export default function* sagas(): Iterable<any> {
  yield takeLatest(REQUEST_SIGNIN_SUCCESS, setUserRequest);
  yield takeLatest(LOCATION_CHANGE, trackRouter);
}
