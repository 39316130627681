//@flow
import React from 'react';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const ModalWrap = styled.div`
  position: absolute;
  top: ${(props) => props.bottom ? 'auto' : '22%'};
  left: ${(props) => props.bottom ? '0' : '50%'};
  bottom: ${(props) => props.bottom ? '0' : 'auto'};
  transform: ${(props) => props.bottom ? 'none' : 'translate(-50%, -22%)'};
  padding: ${(props) => props.action ? '20px 20px 30px' : '20px'};
  width: ${(props) => props.bottom ? '100%' : '80%'};
  background-color: ${(props) => props.theme.palette.primary.contrastText};
  border-radius: 2px;
  outline: none;

  button {
    width: auto;
    font-size: 14px;
    font-weight: 600;

    span {
      color: #be2515;
    }
  }

  @media (min-width: 960px) {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ActionsWrap = styled(Grid)`
  position: absolute;
  bottom: 0;
  right: 0;
`

const GdModal = (props: any) => {
  return (
    <Modal open={props.open} onClose={props.close}>
      <ModalWrap {...props}>
        {props.children}
        <ActionsWrap container direction="row" justify="flex-end">
          <Grid item>{props.action}</Grid>
        </ActionsWrap>
      </ModalWrap>
    </Modal>
  );
};

export default withTheme()(GdModal);
