//@flow
import React from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { withTheme } from '@material-ui/core/styles';

const StepWrap = styled(Step)`
  &&& {
    padding: 8px 2px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const SingleStep = styled.div`
  width: 21px;
  height: 2px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.palette.border.primary};

  &[disabled] {
    background-color: ${(props) => props.theme.palette.border.disabled};
    opacity: 0.2;
  }
`;

const GdStep = (props: any) => {
  const { theme, activeStep } = props;

  return (
    <Stepper count={props.count} activeStep={activeStep}>
      {Array.apply(null, { length: props.count }).map((index, i) => {
        return (
          <StepWrap key={i} disabled={activeStep < i}>
            <SingleStep {...props} theme={theme} />
          </StepWrap>
        );
      })}
    </Stepper>
  );
};

export default withTheme()(GdStep);
