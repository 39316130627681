import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { GdTheme } from './config/theme';
import NotFound from './pages/not-found';
import Rr from './pages/rr';
import A from './pages/a';

const Routes = () => (
  <MuiThemeProvider theme={GdTheme}>
    <Switch>
      <Route path={`/`} exact render={() => <A />} />
      <Route path={`/rr/:code?`} render={() => <Rr />} />
      <Route path={`/a/:code?`} render={() => <A />} />
      <Route path={`/404`} render={() => <NotFound />} />
    </Switch>
  </MuiThemeProvider>
);

export default Routes;
