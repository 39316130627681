//@flow
import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const ListItemWrap = styled.a`
  display: inline-flex;
  width: 100%;
  text-decoration: none;
`

const GdList = (props: any) => {
  return (
    <List {...props}>
    {props.items.map((item, i) => (
      <ListItemWrap key={i} href={item.url} onClick={() => {if(item.onClick) item.onClick()}}>
        <ListItem>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItem>
      </ListItemWrap>
    ))}
    </List>
  );
};

export default GdList;
