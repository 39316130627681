//@flow
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { PinInput } from './';
import styled from 'styled-components';

const PinGrid = styled(Grid)`
  &&& {
    flex-basis: 71px;
    max-width: 71px;

    @media (max-width: 376px) {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
`

class Pin extends Component<*, *> {
  inputRefs = {
    input0: undefined,
    input1: undefined,
    input2: undefined,
    input3: undefined,
  };
  state = { step: 0, value: ['', '', '', ''] };

  render() {
    const { disabled, onChange } = this.props;
    const { step = 0, value } = this.state;
    if ((this.inputRefs[`input${step}`] || step === 0) && !disabled) {
      setTimeout(() => {
        this.inputRefs[`input${step}`].focus();
      }, 1);
    }

    return (
      <Grid container item xs={12} spacing={16} justify="center">
        <PinGrid item>
          <PinInput
            inputRef={(el) => (this.inputRefs.input0 = el)}
            onChange={(e) => {
              const newValue = [e.target.value, value[1], value[2], value[3]];
              this.setState({
                step: 1,
                value: newValue,
              });
              if (onChange) onChange(newValue.join(''));
            }}
          />
        </PinGrid>
        <PinGrid item>
          <PinInput
            inputRef={(el) => (this.inputRefs.input1 = el)}
            disabled={step < 1}
            onChange={(e) => {
              const newValue = [value[0], e.target.value, value[2], value[3]];
              this.setState({
                step: 2,
                value: newValue,
              });
              if (onChange) onChange(newValue.join(''));
            }}
          />
        </PinGrid>
        <PinGrid item>
          <PinInput
            inputRef={(el) => (this.inputRefs.input2 = el)}
            disabled={step < 2}
            onChange={(e) => {
              const newValue = [value[0], value[1], e.target.value, value[3]];
              this.setState({
                step: 3,
                value: newValue,
              });
              if (onChange) onChange(newValue.join(''));
            }}
          />
        </PinGrid>
        <PinGrid item>
          <PinInput
            inputRef={(el) => (this.inputRefs.input3 = el)}
            disabled={step < 3}
            onChange={(e) => {
              const newValue = [value[0], value[1], value[2], e.target.value];
              this.setState({
                step: 4,
                value: newValue,
              });
              if (onChange) onChange(newValue.join(''));
              if (this.inputRefs.input3) this.inputRefs.input3.blur();
            }}
          />
        </PinGrid>
      </Grid>
    );
  }
}

export default Pin;
