import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../lib';

const PageWrap = styled(Grid)`
  width: 100vw;
  height: 100vh;
  background: #563ff1;
  display: flex;
  align-items: center;
  justify-content: center;
  &&& {
    padding: 16px 0 12px;
  }
`;

const ViewWrap = styled(Grid)`
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

class Rr extends Component {
  render() {
    const { user } = this.props;
    return (
      <PageWrap>
        <ViewWrap>
          <img
            src="https://www.rsntr.com/img/logo-resonator.svg"
            alt="Resonator logo"
            width={'40px'}
          />
          {user.firstName && (
            <Text color={'#fff'} size={18}>
              {`${user.firstName} ${user.lastName}`}
            </Text>
          )}
          {!user.firstName && (
            <Text color={'#fff'} size={24}>
              ERROR: User not found
            </Text>
          )}
        </ViewWrap>
      </PageWrap>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

const mapStateToProps = () => (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(Rr);
