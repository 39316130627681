//@flow
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export const ArticleWrap = withTheme()(styled(Grid)`
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.primary.alabaster};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.palette.shadow.multiple};
  text-align: left;

  &&& {
    margin-bottom: 20px;
  }
`);

export const ArticleHeader = styled.a`
  display: inline-flex;
  position: relative;
  width: 100%;
  min-height: 40px;
`;

export const ImageHolder = styled.div`
  width: 100%;
  height: 222px;
  background-image: ${(props) => (props.imageUrl)};
  background-size: cover;
`;

export const HeaderBar = withTheme()(styled(Grid)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 11px 15px;
  width: 100%;
  max-height: 40px;

  span,
  svg {
    position: relative;
    color: ${(props) => props.theme.palette.primary.contrastText};
  }

  svg {
    font-size: 14px;
  }
`);

export const HeaderBarBackground = withTheme()(styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.primary.black};
  opacity: .8;
`);

export const ArticleContent = styled.div`
  padding: 15px;
  width: 100%;
`;

export const ArticleTitle = styled.a`
  display: inline-flex;
  margin-bottom: 5px;
  line-height: 1.38;
  text-decoration: none;
`;

export const ArticleDescription = styled.a`
  display: inline-flex;
  margin-bottom: 15px;
  letter-spacing: -0.2px;
  text-decoration: none;
`;

export const ArticleTopBar = withTheme()(styled(Grid)`
  a {
    position: relative;
    margin-right: 28px;
    color: ${(props) => props.theme.palette.text.dark};
    font-weight: 600;

    &:after {
      position: absolute;
      top: 8px;
      right: -15px;
      content: '';
      width: 3px;
      height: 3px;
      background-color: ${(props) => props.theme.palette.primary.grey};
      border-radius: 50%;
      opacity: .5;
    }
  }

  span {
    opacity: .5;
    font-weight: 600;
  }

  button {
    margin-left: auto;
  }
`);

export const ArticleBottomBar = styled(Grid)`
  padding: 0 3px;
`;

export const ArticleAuthor = withTheme()(styled(Grid)`
  > div {
    margin-right: 15px;
  }

  a {
    color: ${(props) => props.theme.palette.text.light};
    font-weight: 600;

    &:first-of-type {
      color: ${(props) => props.theme.palette.text.dark};
    }
  }
`);
