import { createMuiTheme } from '@material-ui/core/styles';

const GdTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#e72413',
      main: '#e72413',
      dark: '#404040',
      black: '#000000',
      grey: '#595959',
      alabaster: '#fafafa',
      contrastText: '#ffffff',
      pink: '#ffa9a0',
    },
    text: {
      secondary: '#404040',
      primary: '#ffffff',
      dark: '#4a4a4a',
      light: 'rgba(74, 74, 74, 0.5)',
    },
    border: {
      primary: '#e74c3c',
      secondary: '#404040',
      disabled: '#767676',
    },
    shadow: {
      primary: 'rgba(215, 209, 209, 0.11)',
      secondary: '0 1px 4px 0 rgba(27, 47, 54, 0.12)',
      multiple: '0 4px 15px 0 rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.05)',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    useNextVariants: true,
  },
  overrides: {
    Input: {
      hint: {
        primary: 'rgba(0, 0, 0, 0.6)',
        error: '#b00020',
      },
    },
    MuiButton: {
      root: {
        width: '300px',
        height: '48px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '500',
        boxShadow: 'none',
        borderRadius: '30px',
        color: '#ffffff',
        '&$disabled': {
          background: 'rgba(0, 0, 0, 0.12)',
          color: 'rgba(89, 89, 89, 0.38)',
        },
      },
    },
    MuiButtonTypes: {
      primary: {
        background: 'linear-gradient(90deg, #f76b7b, #f3744e, #f05245)',
        border: 'none',
      },
      secondary: {
        background: '#ffffff',
        color: 'primary',
        border: 'solid 1px #e72413',
      },
      link: {
        background: '#ffffff',
        color: 'primary',
        border: 'none',
      },
      inline: {
        background: '#ffffff',
        color: 'primary',
        border: 'none',
        fontSize: '14px',
        width: 'auto',
      },
      linkMedium: {
        color: 'primary',
        fontSize: '14px',
      },
      linkSmall: {
        color: 'primary',
        fontSize: '12px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiStepper: {
      horizontal: {
        justifyContent: 'center',
      },
    },
    MuiStepConnector: {
      root: {
        display: 'none',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiRadio: {
      root: {
        padding: '4px 12px',
        color: 'rgba(0, 0, 0, .5)',
      },
      colorSecondary: {
        '&$checked': {
          color: '#e74c3c',
        },
      },
    },
    MuiFormGroup: {
      root: {
        margin: '-5px 0 17px',
      },
    },
    MuiCheckbox: {
      root: {
        marginLeft: '-15px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      colorSecondary: {
        '&$checked': {
          color: '#e74c3c',
        },
      },
    },
    MuiTypography: {
      body2: {
        color: '#595959',
        fontSize: '16px',
        letterSpacing: '.2px',
        fontWeight: '500',
      },
      body1: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '14px',
        letterSpacing: '.3px',
        fontWeight: '400',
        lineHeight: 1.48,
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '0',
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: '8px',
      },
    },
    MuiBadge: {
      badge: {
        position: 'static',
        width: 'auto',
        height: 'auto',
        color: 'rgba(89, 89, 89, 0.87)',
        fontSize: '12px',
        fontWeight: '500',
        opacity: '.5',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
});

export { GdTheme };
