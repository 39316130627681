//@flow
import styled from 'styled-components';

export const Text = styled.span`
  font-size: ${({ size = 12 }) => size}px;
  color: ${({ color = '#4a4a4a' }) => color};
  opacity: ${({ opacity = 1 }) => opacity};
  font-style: ${({ fontStyle = 'normal' }) => fontStyle};
  font-weight: ${({ weight = 'normal' }) => weight};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`};
  font-family: Poppins;
  ${({ capitalize }) => capitalize && 'text-transform: capitalize;'};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'};
  ${({ noWrap }) => noWrap && 'white-space: nowrap;'};
  ${({ preWrap }) => preWrap && 'white-space: pre;'};
  ${({ normalWrap }) => normalWrap && 'white-space: normal;'};
  ${({ underlined }) => underlined && 'text-decoration: underline;'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
`;

export const ColorText = styled.span`
  font-weight: ${({ weight = 'normal' }) => weight};
`;
