import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Text } from '../../lib';

const LogoWrap = styled(Grid)`
  width: 60px;
  height: 60px;

  & img + img {
    position: absolute;
    left: 10px;
    top: 10px;
  }
`;

const PageWrap = styled(Grid)`
  width: 100vw;
  height: 100vh;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  &&& {
    padding: 16px 0 12px;
  }
`;

const ViewWrap = styled(Grid)`
  position: relative;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

class A extends Component {
  render() {
    const { user } = this.props;
    return (
      <PageWrap>
        <ViewWrap>
          <LogoWrap>
            <img
              src="https://www.weareathlon.com/public/app/assets/img/athlon-logo-element.svg"
              alt=""
              width="60"
            />
            <img
              src="https://www.weareathlon.com/public/app/assets/img/athlon-logo-a.svg"
              alt="Athlon logo"
              width={'40px'}
            />
          </LogoWrap>
          {user.firstName && (
            <Text color={'#fff'} size={18}>
              {`${user.firstName} ${user.lastName}`}
            </Text>
          )}
          {!user.firstName && (
            <Text color={'#fff'} size={18}>
              ERROR: User not found
            </Text>
          )}
        </ViewWrap>
      </PageWrap>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

const mapStateToProps = () => (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(A);
