//@flow
import React from 'react';
import styled from 'styled-components';
import { Text } from '../lib';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withTheme } from '@material-ui/core/styles';
import { InputWrap } from './styles';

const HelperWrap = styled(Grid)`
  padding-left: 5px;
`;

const GdInputField = styled(TextField)`
  width: 100%;

  input {
    color: #404040;
    font-size: 16px;
    font-family: Poppins;
    padding-bottom: 10px;
  }

  label {
    color: #404040;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
  }

  &:focused {
    label {
      font-size: 6px;
    }
  }
`;

const GdInput = ({ theme, errorText, value, hint, icon, ...props }) => {
  return (
    <InputWrap icon={icon}>
      <GdInputField {...props} classes={theme.overrides.Input.label} label={props.label} />
      {hint &&
        !value &&
        !errorText && (
          <HelperWrap item>
            <Text size={10} color={theme.overrides.Input.hint.primary}>
              {hint}
            </Text>
          </HelperWrap>
        )}
      {errorText && (
        <HelperWrap item>
          <Text size={10} color={theme.overrides.Input.hint.error}>
            {errorText}
          </Text>
        </HelperWrap>
      )}
    </InputWrap>
  );
};

export default withTheme()(GdInput);
